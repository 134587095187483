<template>
    <!-- 导航 -->
    <page-head title="机构介绍"/>
    <content-bg>
        <template v-slot:content>
            <div class="content">
                <p class="title">机构介绍</p>
                <p class="introduceText">
                    中国国家女子足球队建立于1984年，由中国足球协会管理。
                    1986年首次参加亚洲杯就获得冠军， 自此开创1986、1989、1991、1993、1995、1997、1999年女足亚洲杯七连冠，14次参与亚洲杯共获得冠军8次，亚军2次，季军3次。
                    参加亚运会8次获得冠军3次、亚军2次、季军1次，1990、1994和1998年亚运会女足获三连冠。参赛女足世界杯8次，获得亚军1次，进入四强1次（不含前述亚军）。闯入奥运会5次，其中在1996年亚特兰大奥运会上获得亚军，为中国女足在奥运会上取得的最好成绩。
                </p>
                <div class="list">
                    <dl>
                        <dt @click="goMoment">精彩瞬间 <img src="@i/introduce/go.svg" alt=""></dt>
                        <dd><img src="@i/introduce/test.png" alt=""></dd>
                    </dl>
                    <dl>
                        <dt @click="goVideo">视频资料 <img src="@i/introduce/go.svg" alt=""></dt>
                        <dd><img src="@i/introduce/test2.png" alt=""></dd>
                    </dl>
                </div>
            </div>
        </template>
    </content-bg>
</template>

<script>
import {useRouter} from "vue-router";
import {getCurrentInstance, reactive, toRefs, onMounted, watch} from "vue";
import {useStore} from "vuex";

export default {
    name: "introduce",

    setup() {

        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({});

        const goMoment = () => {
            router.push({
                path: '/introduce/moment',
                name: 'moment'
            })
        }

        const goVideo = () => {
            router.push({
                path: '/introduce/video',
                name: 'videoIntroduce'
            })
        }

        return {
            goMoment,
            goVideo
        }

    },
}
</script>

<style scoped lang="scss">
.content {
    padding-left: 100px;
    padding-right: 100px;
    box-sizing: border-box;
}

.title {
    padding-top: 44px;
    padding-bottom: 30px;
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
}

.introduceText {
    font-family: PingFang;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: rgba(255, 255, 255, .8);
    text-indent: 50px;
    letter-spacing: 2px;
}

.list {
    margin-top: 20px;

    dl {
        text-align: center;
        font-family: PingFang;
        font-size: 24px;
        color: rgba(255, 255, 255);
        width: 48%;

        &:first-child {
            float: left;
        }

        &:last-child {
            float: right;
        }

        dt {
            height: 66px;
            line-height: 66px;
            background: linear-gradient(180deg, #0F1016 0%, rgba(15, 16, 22, 0.7) 100%);
            backdrop-filter: blur(27px);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 24px;
                margin-left: 10px;
            }
        }
    }

    img {
        width: 100%;
    }
}
</style>